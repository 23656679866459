import * as React from 'react';
import {ReactNode} from 'react';
import styled from 'styled-components';
import {Layout} from "./Layout";
import {PageState} from "./PageState";
import {CenterSectionCSS} from "../constants";

export type MinimalPageProps = {
    children: ReactNode;
    title: string;
    img?: string;
} & PageState;

export function MinimalPage({title, children, img, ...pageStateProps}: MinimalPageProps) {
    return <Container {...pageStateProps}>
        <Content id={"content"}>
            {children}
        </Content>
    </Container>
}

const Container = styled(Layout)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  border: 1px solid;     
`;

const Content = styled.div`
    ${CenterSectionCSS};  
`;
