import * as React from 'react';
// @ts-ignore
import imgSrc from "../images/undraw_not_found.svg";
import {Button} from "primereact/button";
import {Link} from "../components/Link";
import {MinimalPage} from "../containers/MinimalPage";
import {PageProps} from "gatsby";


export default function Page404(props: PageProps) {
    return <MinimalPage title={"404 Page Not Found"} img={imgSrc} {...props}>
        <h3>Whoops! It looks like you could do with a buddy to help you back to the site.</h3>
        <p>No worries, we've got your back. Here's a helpful button... </p>
        <Link to={"/"}><Button icon={"pi pi-chevron-left"} className='p-button-secondary' label={"Back to site"} /></Link>
    </MinimalPage>
}
